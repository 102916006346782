<template>
    <div class="certificate-item">
        <div class="add-template">
            <el-button type="primary"  @click="addTemplateBtn()">添加证书模板</el-button>
        </div>
        <el-table :data="templateTable" style="width: 100%; flex: 1" height="1%" size="medium"
                  :header-cell-style="{fontWeight: 'normal', color: '#666', background: '#eee',fontSize: '16px',height:'60px'}"
                  class="customTable" :cell-style="{fontSize: '16px',color: '#333',height: '70px'}">
            <el-table-column prop="certificate_name" label="证书名称"></el-table-column>
            <el-table-column label="操作" align="center" width="200px">
                <template slot-scope="scope">
                    <div class="link-list">
                        <el-link type="success" :underline="false" @click="editCertificate(scope.row.id)">编辑</el-link>
                        <el-link type="primary" :underline="false" @click="delCertificate(scope.row.id)">删除</el-link>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                class="pages-center"
                :current-page="certificatePages.currentPageNum"
                :page-size="certificatePages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="certificatePages.total"
                v-if="templateTable.length >0"
                @current-change="certificateCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import {certificateTemplateList,delCertificateTemplate} from '@/utils/apis'
    export default {
        name: "Model",
        data(){
            return{
                templateTable:[],
                //分页
                certificatePages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
            }
        },
        mounted() {
            this.getCertificateTemplate()
        },
        methods:{
            //添加证书模板
            addTemplateBtn() {
                let routeDate = this.$router.resolve({
                    path: '/evaluationCenter/certificateModel',
                })
                window.open(routeDate.href, '_blank')
            },
            //获取证书模板列表
            getCertificateTemplate(){
                let params = {
                    paging:1,
                    pageSize:this.certificatePages.eachPageNum,
                    page:this.certificatePages.currentPageNum
                }
                certificateTemplateList(params).then((res) => {
                    this.templateTable = res.data.data ? res.data.data : []
                    this.certificatePages.total = res.data.total ? res.data.total : 0
                }).catch((err) => {
                    console.log(err);
                });
            },
            //编辑
            editCertificate(id){
                let routeDate = this.$router.resolve({
                    path: '/evaluationCenter/certificateModel',
                    query:{
                        id:id
                    }
                })
                window.open(routeDate.href, '_blank')
            },
            //删除
            delCertificate(id){
                this.$confirm(`是否删除该证书模板，删除将无法恢复`, '删除', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box',
                    type: 'warning'
                }).then(() => {
                    let param = {
                        id: id
                    }
                    delCertificateTemplate(param).then((res) => {
                        this.$message.success(res.msg);
                        this.getCertificateTemplate();
                    }).catch((err) => {
                        console.log(err);
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                })
            },
            // 切换分页
            certificateCurrentChange(val){
                this.certificatePages.currentPageNum = val;
                this.getCertificateTemplate()
            },
        }
    }
</script>

<style scoped lang="scss">
    .certificate-item{
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0 20px;
        .add-template{
            display: flex;
            justify-content: flex-end;
            margin: 20px 0;
        }
    }

</style>